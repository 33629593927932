import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Menu, Header } from '../components';
import '../styles/index.css';

function Index() {
  return (
    <main>
      <Helmet>
        <title>Jacqlyn Bender</title>
      </Helmet>
      <Header />
      <Menu />
    </main>
  );
}

export default Index;
