import React from 'react';
import { Link } from 'gatsby';
import '../styles/index.css';

export function Menu() {
  return (
    <div className="menu">
      <Link to="/HomePage" className="menu__link">
        Home
      </Link>
      <Link to="/AboutPage" className="menu__link">
        About
      </Link>
      <Link to="/ProjectsPage" className="menu__link">
        Projects/Experience
      </Link>
    </div>
  );
}
